import type { LinkCTAType, NoneCTAType } from '@/sanity/queries/components/builders/ctaBuilder'
import { CTAActionTypes } from '@/sanity/types'

export const valueOrUndefined = (value: any) => value ?? undefined

export const contentLinkCTAMapper = (linkCTA?: LinkCTAType | NoneCTAType | null) => {
  if (linkCTA?.action === CTAActionTypes.LINK)
    return {
      linkCTA: {
        text: linkCTA.text,
        url: linkCTA.link?.href,
        linkProps: {
          openNewTab: linkCTA.link?.blank ?? false,
        },
      },
    }

  // If cta action is NONE, hide the original link
  if (linkCTA?.action === CTAActionTypes.NONE) return { linkCTA: null }

  // If cta action is not defined, keep the original link
  return { linkCTA: undefined }
}
