import {
  BUTTON_VARIANTS,
  ButtonModes,
  INQUIRY_CATEGORIES,
  matchInternalPath,
} from '@zwirner/design-system'
import Router from 'next/router'

import { CTA_TEXT } from '@/common/constants/cart'
import {
  INQUIRE,
  LEARN_MORE,
  PURCHASE,
  REQUEST_A_PREVIEW,
  TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
} from '@/common/constants/commonCopies'
import { safeText } from '@/common/utilsMappers/safe'
import type { InquireModalProps } from '@/components/hooks/useOpenInquiryDispatch'
import type { PageBuilderNames } from '@/components/pageBuilder/types'
import { PageBuilderComponents } from '@/components/pageBuilder/types'
import { ModalTriggerEvent, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import type { CTABuilderType, LinkType } from '@/sanity/queries/components/builders/ctaBuilder'
import type { PopUpInfo } from '@/sanity/services/popups/getAllCampaigns'
import type { CTASchemaType } from '@/sanity/types'
import { CTAActionTypes, ModalTypes } from '@/sanity/types'

import type { RegisteredInterestModalProps } from '../components/popups/modalTriggerListener/useRegisteredInterestFormModal'
import { SUBSCRIBE_METHOD } from '../constants/subscribe'
import { GTMExitLinkEvent } from '../utils/gtm/gtmExitLinkEvent'

export type SimpleCTA = { action: CTAActionTypes; text: string; link?: LinkType }

export type CTAWebFields = Partial<{
  downloadDoc?: string
  link?: string
  blank?: boolean
  text?: string
  handleClick?: (action?: CTAActionTypes | null, props?: unknown) => void
}>
export type CTAExtended = (CTAWebFields & CTABuilderType) | (CTAWebFields & SimpleCTA)

export type RegisteredInterestPopupEventDetails = {
  modalType: ModalTypes.REGISTERED_INTEREST
  props: RegisteredInterestModalProps
} & (
  | {
      popupInfo: PopUpInfo
      triggerType: ModalTriggerTypes.POPUP
    }
  | {
      triggerType: ModalTriggerTypes.CTA
      popupInfo?: never
    }
)

export type InquiryPopupEventDetails = {
  modalType: ModalTypes.INQUIRE
  props: InquireModalProps
} & (
  | {
      popupInfo: PopUpInfo
      triggerType: ModalTriggerTypes.POPUP
    }
  | {
      triggerType: ModalTriggerTypes.CTA
      popupInfo?: never
    }
)

type CtaMapperProps = {
  data: {
    primaryCTA: CTAExtended
    secondaryCTA?: CTAExtended
    handleLineAdd?: any
  }
  props?: {
    linkAsButton?: boolean
    ctaActionProps?: unknown
    secondaryCtaActionProps?: unknown
    url?: string
    hideSecondary?: boolean
    defaultLinkText?: string
  }
}

// TODO UNIFY CTA'S & TYPES FOR THEM
interface CtaMapperInterstitial {
  data: any
  props?: any
  secondaryProps?: any
}

export enum CTA_CLICK_SOURCE {
  INTERSTITIAL = 'INTERSTITIAL',
}

const handleCTAClick = (
  action: CTAActionTypes.INQUIRE | CTAActionTypes.NEWSLETTER | CTAActionTypes.REGISTERED_INTEREST,
  extraProps?: any,
  source?: keyof typeof CTA_CLICK_SOURCE
) => {
  let modalProps = {}
  if (action === CTAActionTypes.INQUIRE && source === CTA_CLICK_SOURCE.INTERSTITIAL) {
    modalProps = {
      title: INQUIRE,
      subtitle: TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
      inquiryCategory: INQUIRY_CATEGORIES.GENERAL,
    }
  }

  if (action === CTAActionTypes.REGISTERED_INTEREST && source === CTA_CLICK_SOURCE.INTERSTITIAL) {
    const portableTextSubtitle = safeText({
      key: 'subtitle',
      text: extraProps?.registeredInterest?.description,
    })

    modalProps = {
      title: extraProps?.registeredInterest?.title,
      ...portableTextSubtitle,
      inquiryCategory: INQUIRY_CATEGORIES.REGISTERED_INTEREST,
      primaryCTA: { text: extraProps?.ctaText },
    }
  }

  let modalType = ModalTypes.NEWSLETTER
  if (action === CTAActionTypes.INQUIRE) {
    modalType = ModalTypes.INQUIRE
  } else if (action === CTAActionTypes.REGISTERED_INTEREST) {
    modalType = ModalTypes.REGISTERED_INTEREST
  }

  window.document.dispatchEvent(
    ModalTriggerEvent({
      modalType,
      props: { ...extraProps, ...modalProps },
      triggerType: ModalTriggerTypes.CTA,
    })
  )
}

const externalFlags = ['www.', 'https://', '.com']
const internalFlags = ['.davidzwirner.com', '.zwirner.dev', '.zwirner.tech']

const handleLink = ({ linkedHref, action, blank, downloadDoc, identifier }: any) => {
  const linkInternalContent = action === CTAActionTypes.LINK_CONTENT
  const linkCustomUrl = action === CTAActionTypes.LINK || action === CTAActionTypes.CUSTOM
  const downloadPdf = action === CTAActionTypes.DOWNLOAD_PDF

  if (
    ![
      CTAActionTypes.LINK_CONTENT,
      CTAActionTypes.LINK,
      CTAActionTypes.DOWNLOAD_PDF,
      CTAActionTypes.CUSTOM,
    ].includes(action)
  )
    return

  if ((linkCustomUrl && blank) || downloadPdf) {
    GTMExitLinkEvent(identifier, linkedHref)
    return window.open(downloadPdf ? downloadDoc : linkedHref, '_blank')
  }

  if (!Router || !linkedHref) return

  if (linkInternalContent) {
    if (matchInternalPath(linkedHref)) {
      return Router.push(linkedHref)
    } else {
      return Router.replace(linkedHref)
    }
  }

  if (
    externalFlags.some((e) => linkedHref.includes(e)) &&
    !internalFlags.some((e) => linkedHref.includes(e))
  ) {
    GTMExitLinkEvent(identifier, linkedHref)
    return Router.replace(linkedHref)
  }
  return Router.push(linkedHref)
}

const handleEcommClick = (url: string | undefined) => {
  if (!Router || !url) return

  Router.push(url)
}

// WIP: This needs to be marked as deprecated once the mapper is done
export const ctaMapper = ({ data: { primaryCTA, secondaryCTA }, props }: CtaMapperProps) => {
  const { action, link, text, handleClick, downloadDoc } = primaryCTA ?? {}

  // TODO unify CTA inside the studio
  const { blank, href } = (link as any) ?? {}
  const { action: secondaryAction, text: secondaryText, link: secondaryLink } = secondaryCTA ?? {}
  const { blank: secondaryBlank, href: secondaryHref } = (secondaryLink as any) ?? {}
  const primaryActionIsLink =
    action === CTAActionTypes.LINK_CONTENT || action === CTAActionTypes.LINK

  const {
    url,
    hideSecondary = false,
    defaultLinkText,
    ctaActionProps,
    secondaryCtaActionProps,
    linkAsButton,
  } = props ?? {}

  const linkedHref = action === CTAActionTypes.LINK_CONTENT ? url : href
  const plainUrl = typeof link === 'string' ? link : null

  const primaryCTAMap =
    primaryCTA && (linkAsButton || !primaryActionIsLink)
      ? {
          primaryCTA: {
            text,
            action,
            ctaProps: {
              onClick: () => {
                handleLink({
                  action,
                  blank,
                  linkedHref: linkedHref ?? plainUrl,
                  downloadDoc,
                  identifier: text,
                })
                if (action === CTAActionTypes.ECOMM) {
                  handleEcommClick(props?.url)
                }

                if (handleClick) handleClick(action)

                if (
                  action === CTAActionTypes.INQUIRE ||
                  action === CTAActionTypes.NEWSLETTER ||
                  action === CTAActionTypes.REGISTERED_INTEREST
                )
                  handleCTAClick(action, ctaActionProps)
              },
              disabled: action === CTAActionTypes.SOLD_OUT,
              mode: ButtonModes.DARK,
            },
          },
        }
      : {}

  const linkCTAMap =
    primaryActionIsLink && !linkAsButton
      ? {
          linkCTA: {
            text: text ?? defaultLinkText ?? LEARN_MORE,
            url: linkedHref,
            openNewTab: blank ?? false,
          },
        }
      : {}

  const secondaryCTAMap =
    secondaryCTA && !hideSecondary
      ? {
          secondaryCTA: {
            text: secondaryText,
            ctaProps: {
              variant: BUTTON_VARIANTS.TERTIARY,
              onClick: () => {
                handleLink({
                  action: secondaryAction,
                  blank: secondaryBlank,
                  linkedHref: secondaryHref,
                  identifier: secondaryText,
                })

                if (secondaryAction === CTAActionTypes.ECOMM) {
                  handleEcommClick(props?.url)
                }

                if (
                  secondaryAction === CTAActionTypes.INQUIRE ||
                  secondaryAction === CTAActionTypes.NEWSLETTER ||
                  secondaryAction === CTAActionTypes.REGISTERED_INTEREST
                )
                  handleCTAClick(secondaryAction, secondaryCtaActionProps)
              },
            },
          },
        }
      : {}

  return {
    ...primaryCTAMap,
    ...linkCTAMap,
    ...secondaryCTAMap,
  }
}

// TODO unify ctas everywhere
export const ctaMapperInterstitial = ({ data, props }: CtaMapperInterstitial) => {
  const { action, text, handleClick, linkedContent, link, downloadDoc } = data ?? {}
  // TODO unify CTA inside the studio
  const { blank, href } = (link as any) ?? {}
  const linkedHref = action === CTAActionTypes.LINK_CONTENT ? linkedContent : href
  const { tags, registeredInterest } = props ?? {}
  const primaryCTAMap = text
    ? {
        primaryCta: {
          text: text,
          ctaProps: {
            onClick: () => {
              handleLink({
                action,
                downloadDoc,
                blank,
                linkedHref,
                identifier: text,
              })

              // TODO Unify handle click && custom Action
              if (handleClick) {
                handleClick(action)
              } else {
                handleCTAClick(
                  action,
                  {
                    ctaText: text,
                    method: SUBSCRIBE_METHOD.INTERSTITIAL,
                    tags,
                    registeredInterest,
                  },
                  CTA_CLICK_SOURCE.INTERSTITIAL
                )
              }
            },
          },
        },
      }
    : {}

  return {
    ...primaryCTAMap,
  }
}

export const artworkCTAMapper = (artworkCTA: CTASchemaType, product: any): SimpleCTA | null => {
  switch (artworkCTA?.action) {
    case CTAActionTypes.ECOMM:
      if (!product) return null
      return {
        action: product.variants[0].store.inventory.isAvailable
          ? CTAActionTypes.ECOMM
          : CTAActionTypes.SOLD_OUT,
        text: !product.variants[0].store.inventory.isAvailable
          ? CTA_TEXT.SOLD_OUT
          : artworkCTA?.text || CTA_TEXT.PURCHASE,
      }
    case CTAActionTypes.SOLD_OUT:
      return {
        action: CTAActionTypes.SOLD_OUT,
        text: artworkCTA.text || CTA_TEXT.SOLD_OUT,
      }
    case CTAActionTypes.INQUIRE:
      return {
        action: CTAActionTypes.INQUIRE,
        text: artworkCTA.text || CTA_TEXT.INQUIRE,
      }
    case CTAActionTypes.LINK:
    case CTAActionTypes.CUSTOM:
      return {
        action: artworkCTA.action,
        text: artworkCTA.text || CTA_TEXT.CUSTOM,
        link: artworkCTA.link,
      }
  }

  return null
}

type CTAOptions = {
  asLink: boolean
  source?: string
  modalProps?: Record<string, any>
  ecommProps?: Record<string, any>
  ecommAction?: (props: Record<string, any>) => void
}
export type CTAMapperPropsType = {
  data: CTABuilderType | null | undefined
  options: CTAOptions
}

export enum MappedCTATypes {
  LINK = 'Link',
  BUTTON = 'Button',
}

type MappedCTA = {
  type: MappedCTATypes
  supported: boolean
  text?: string
  onClick?: () => void
  url?: string | null
  openNewTab?: boolean | null
  disabled?: boolean
  action?: string
}

// WIP: implement transformer for all molecules, not vital for GoSC
const ctaTransformerByMolecule: Record<PageBuilderNames, (data: MappedCTA) => any> = {
  [PageBuilderComponents.dzCard]: (cta: MappedCTA) => {
    const { text = LEARN_MORE, url, onClick, openNewTab = false, disabled = false } = cta
    if (cta.type === MappedCTATypes.LINK && url) {
      return {
        linkCTA: {
          text,
          url,
          linkProps: {
            openNewTab: !!openNewTab,
          },
        },
      }
    }
    if (cta.type === MappedCTATypes.BUTTON && onClick) {
      return {
        primaryCTA: {
          text,
          ctaProps: {
            variant: BUTTON_VARIANTS.PRIMARY,
            onClick,
            disabled: disabled ?? false,
          },
        },
      }
    }
    return {}
  },
  [PageBuilderComponents.dzCarousel]: () => {},
  [PageBuilderComponents.dzEditorial]: () => {},
  [PageBuilderComponents.dzGrid]: () => {},
  [PageBuilderComponents.dzHero]: (cta: MappedCTA) => {
    const { text = LEARN_MORE, url, onClick, openNewTab = false, disabled = false } = cta
    if (cta.type === MappedCTATypes.LINK && url) {
      return {
        linkCTA: {
          text,
          url,
          linkProps: {
            openNewTab: !!openNewTab,
          },
        },
      }
    }
    if (cta.type === MappedCTATypes.BUTTON && onClick) {
      return {
        buttonCTA: {
          text,
          ctaProps: {
            variant: BUTTON_VARIANTS.PRIMARY,
            onClick,
            disabled: disabled ?? false,
          },
        },
      }
    }
    return {}
  },
  [PageBuilderComponents.dzInterstitial]: (cta: MappedCTA) => {
    const { text = LEARN_MORE, onClick, disabled = false } = cta
    // By default interstitial only have buttons

    if (!onClick) return {}

    return {
      primaryCta: {
        text,
        ctaProps: {
          variant: BUTTON_VARIANTS.PRIMARY,
          onClick,
          disabled: disabled ?? false,
        },
      },
    }
  },
  [PageBuilderComponents.dzMedia]: () => {},
  [PageBuilderComponents.dzSplit]: (cta: MappedCTA) => {
    const { text = LEARN_MORE, url, onClick, openNewTab = false, disabled = false } = cta
    if (cta.type === MappedCTATypes.LINK && url) {
      return {
        linkCTA: {
          text,
          url,
          linkProps: {
            openNewTab: !!openNewTab,
          },
        },
      }
    }
    if (cta.type === MappedCTATypes.BUTTON && onClick) {
      return {
        buttonCTA: {
          text,
          ctaProps: {
            variant: BUTTON_VARIANTS.PRIMARY,
            onClick,
            disabled: disabled ?? false,
          },
        },
      }
    }
    return {}
  },
  [PageBuilderComponents.dzLanding]: () => {},
  [PageBuilderComponents.dzGalleryHero]: () => {},
  [PageBuilderComponents.dzImageMosaic]: () => {},
}

const DEFAULT_DISABLED_CTA: MappedCTA = {
  type: MappedCTATypes.BUTTON,
  supported: false,
}

export type CtaActionsNames = (typeof CTAActionTypes)[keyof typeof CTAActionTypes]

const ctaMapperByType: Record<CtaActionsNames, (data: CTAMapperPropsType) => MappedCTA> = {
  [CTAActionTypes.INQUIRE]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.INQUIRE) return DEFAULT_DISABLED_CTA
    const { source, modalProps } = options
    const { text } = data
    const textWithFallback = text ?? LEARN_MORE
    // This overrides title, subtitles & inquiryCategory from the popup
    // If we need to include other titles for popups lets improve this logic
    const sourceProps =
      source === CTA_CLICK_SOURCE.INTERSTITIAL
        ? {
            title: INQUIRE,
            subtitle: TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
            inquiryCategory: INQUIRY_CATEGORIES.GENERAL,
          }
        : {}

    const mappedModalProps = modalProps ?? {}
    if (!modalProps?.contextData?.ctaText) {
      mappedModalProps.contextData = {
        ...(mappedModalProps?.contextData ?? {}),
        ctaText: text ?? INQUIRE, //ctaText is required for inquire
      }
    }

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.INQUIRE,
            props: { ...mappedModalProps, ...sourceProps } as InquireModalProps,
            triggerType: ModalTriggerTypes.CTA,
          } satisfies InquiryPopupEventDetails)
        )
      },
    }
  },
  [CTAActionTypes.REGISTERED_INTEREST]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.REGISTERED_INTEREST) return DEFAULT_DISABLED_CTA
    const { source, modalProps } = options
    const { text } = data
    const textWithFallback = text ?? LEARN_MORE

    let sourceProps = {}
    if (source === CTA_CLICK_SOURCE.INTERSTITIAL) {
      const portableTextSubtitle = safeText({
        key: 'subtitle',
        text: modalProps?.registeredInterest?.description,
      })

      sourceProps = {
        title: modalProps?.registeredInterest?.title,
        ...portableTextSubtitle,
        inquiryCategory: INQUIRY_CATEGORIES.REGISTERED_INTEREST,
        primaryCTA: { text },
      }
    }

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.REGISTERED_INTEREST,
            props: { ...modalProps, ...sourceProps } as RegisteredInterestModalProps,
            triggerType: ModalTriggerTypes.CTA,
          } satisfies RegisteredInterestPopupEventDetails)
        )
      },
    }
  },
  [CTAActionTypes.SOLD_OUT]: () => {
    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      disabled: true,
    }
  },
  [CTAActionTypes.REQUEST_A_PREVIEW]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.REQUEST_A_PREVIEW) return DEFAULT_DISABLED_CTA
    const { source, modalProps } = options
    const { text } = data
    const textWithFallback = text ?? LEARN_MORE
    // This overrides title, subtitles & inquiryCategory from the popup
    // If we need to include other titles for popups lets improve this logic
    const sourceProps =
      source === CTA_CLICK_SOURCE.INTERSTITIAL
        ? {
            title: INQUIRE,
            subtitle: TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
            inquiryCategory: INQUIRY_CATEGORIES.REQUEST_PREVIEW,
          }
        : {}

    const mappedModalProps = modalProps ?? {}
    if (!modalProps?.contextData?.ctaText) {
      mappedModalProps.contextData = {
        ...(mappedModalProps?.contextData ?? {}),
        ctaText: text ?? REQUEST_A_PREVIEW, //ctaText is required for inquire
      }
    }

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.REQUEST_A_PREVIEW,
            props: {
              ...mappedModalProps,
              ...sourceProps,
            },
            triggerType: ModalTriggerTypes.CTA,
          })
        )
      },
    }
  },
  [CTAActionTypes.ECOMM]: ({ data }) => {
    if (!data || data.action !== CTAActionTypes.ECOMM) return DEFAULT_DISABLED_CTA
    const { text } = data
    // While we use Shopify buy now option to do ECOMM this logic is not needed
    // const {ecommProps} = options
    // const {ecommAction} = options
    // if (!ecommProps || !ecommAction) return DEFAULT_DISABLED_CTA
    const textWithFallback = text ?? PURCHASE
    return {
      // By default all ecomm options are buttons
      type: MappedCTATypes.BUTTON,
      text: textWithFallback,
      // onClick: () => {
      //   ecommAction(ecommProps)
      // },
      // While we use Shopify buy now option to do ECOMM this will be disabled
      supported: false,
    }
  },
  [CTAActionTypes.CUSTOM]: ({ data }) => {
    if (!data || data.action !== CTAActionTypes.CUSTOM) return DEFAULT_DISABLED_CTA
    return {
      type: MappedCTATypes.BUTTON,
      supported: false,
    }
  },
  [CTAActionTypes.NONE]: () => {
    return DEFAULT_DISABLED_CTA
  },
  [CTAActionTypes.NEWSLETTER]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.NEWSLETTER) return DEFAULT_DISABLED_CTA
    const { modalProps } = options
    const { text } = data
    const textWithFallback = text ?? LEARN_MORE
    // This overrides title, subtitles & inquiryCategory from the popup
    // If we need to include other titles for popups lets improve this logic

    return {
      type: MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        window.document.dispatchEvent(
          ModalTriggerEvent({
            modalType: ModalTypes.NEWSLETTER,
            props: { ...modalProps },
            triggerType: ModalTriggerTypes.CTA,
          })
        )
      },
    }
  },
  [CTAActionTypes.LINK]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.LINK) return DEFAULT_DISABLED_CTA
    const { text, link } = data
    const textWithFallback = text ?? LEARN_MORE
    if (!link) return DEFAULT_DISABLED_CTA
    const { href, blank } = link
    return {
      type: options?.asLink ? MappedCTATypes.LINK : MappedCTATypes.BUTTON,
      supported: true,
      text: textWithFallback,
      onClick: () => {
        if (!Router || !href) return
        // Check if the URL is external
        if (!blank) return Router.push(href)

        if (
          externalFlags.some((e) => href.includes(e)) &&
          !internalFlags.some((e) => href.includes(e))
        ) {
          GTMExitLinkEvent(textWithFallback, href)
          return window.open(href, '_blank')
        }
        return Router.push(href)
      },
      url: href,
      openNewTab: blank,
    }
  },
  [CTAActionTypes.DOWNLOAD_PDF]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.DOWNLOAD_PDF) return DEFAULT_DISABLED_CTA
    const { text, file } = data
    const textWithFallback = text ?? LEARN_MORE
    if (!file) return DEFAULT_DISABLED_CTA
    const { asset } = file
    const { url } = asset
    if (!url) return DEFAULT_DISABLED_CTA

    return {
      type: options?.asLink ? MappedCTATypes.LINK : MappedCTATypes.BUTTON,
      onClick: () => {
        GTMExitLinkEvent(textWithFallback, url)
        return window.open(url, '_blank')
      },
      // By default download docs should open new tabs
      openNewTab: true,
      url,
      text: textWithFallback,
      supported: true,
    }
  },
  [CTAActionTypes.LINK_CONTENT]: ({ data, options }) => {
    if (!data || data.action !== CTAActionTypes.LINK_CONTENT) return DEFAULT_DISABLED_CTA
    const { text, url } = data
    if (!url) return DEFAULT_DISABLED_CTA
    const textWithFallback = text ?? LEARN_MORE
    return {
      type: options?.asLink ? MappedCTATypes.LINK : MappedCTATypes.BUTTON,
      supported: true,
      onClick: () => {
        if (!Router || !url) return
        if (matchInternalPath(url)) {
          return Router.push(url)
        } else {
          return Router.replace(url)
        }
      },
      // By default internal links open in the same tab
      openNewTab: false,
      text: textWithFallback,
      url,
    }
  },
}

export const singleUnitMapper = (props: CTAMapperPropsType) => {
  if (!props || !props.data) return null
  const ctaMapper = ctaMapperByType[props.data.action]
  if (!ctaMapper) return null
  return ctaMapper(props)
}

export const simpleMoleculeMapper = (component: PageBuilderNames) => {
  return ctaTransformerByMolecule[component]
}

export const ctaMapperByMoleculeType = (component: PageBuilderNames) => {
  // Get CTA transformer by molecule
  const moleculeMapper = ctaTransformerByMolecule[component]
  // Return CTA function for molecule
  return (props: CTAMapperPropsType) => {
    const transformedCTA = singleUnitMapper(props)
    if (!transformedCTA || !transformedCTA.supported) return {}
    return moleculeMapper(transformedCTA)
  }
}
