import { BUTTON_VARIANTS, CardTypes, MEDIA_OBJECT_FIT } from '@zwirner/design-system'
import Image from 'next/image'

import { LEARN_MORE, PURCHASE } from '@/common/constants/commonCopies'
import {
  gtmProductListingItemClickedEvent,
  gtmProductListingViewedEvent,
} from '@/common/utils/gtm/gtmProductEvent'
import type { CTAMapperPropsType } from '@/common/utilsMappers/cta.mapper'
import { singleUnitMapper } from '@/common/utilsMappers/cta.mapper'
import { dzMediaMapper, getSizesByCardSize } from '@/common/utilsMappers/image.mapper'
import { createPortableTextElement, safeText } from '@/common/utilsMappers/safe'
import type { CTABuilderType } from '@/sanity/queries/components/builders/ctaBuilder'
import type { BookContentType } from '@/sanity/queries/components/content/bookContent'
import type { DzCardExtendedProps } from '@/sanity/types'
import { BookVariation, CTAActionTypes } from '@/sanity/types'

export const bookMapper = ({
  data,
  props,
}: {
  data: BookContentType
  props: DzCardExtendedProps
}) => {
  const {
    title,
    subtitle,
    price,
    tagline,
    publisher,
    marketingDescription,
    bookCTA,
    slug,
    compareAtPrice,
  } = data
  const url = slug?.current
  const ctaBook = { linkCTA: { text: LEARN_MORE, url } }
  const { cardSize, bookVariation, bookFilters } = props ?? {}
  const { media, hideImage } = dzMediaMapper({
    data: data,
    ImgElement: Image,
    extraImgProps: {
      sizes: getSizesByCardSize(cardSize),
    },
  })

  const mapCTA = (cta: CTABuilderType, url: string | null | undefined) => {
    if (!cta) return

    const mappedCTA = singleUnitMapper({
      data:
        cta?.action && cta?.action === CTAActionTypes.ECOMM
          ? {
              action: CTAActionTypes.LINK,
              text: cta.text ? cta.text : PURCHASE,
              link: {
                blank: false,
                href: url,
              },
            }
          : cta,
    } as CTAMapperPropsType)

    if (cta?.action === CTAActionTypes.NONE) {
      return null
    }

    return {
      ...mappedCTA,
      ctaProps: {
        onClick: mappedCTA?.onClick,
        variant: cta === bookCTA?.secondaryCTA ? BUTTON_VARIANTS.TERTIARY : undefined,
      },
    }
  }

  const primaryCTA = mapCTA(bookCTA?.primaryCTA as CTABuilderType, url)
  const secondaryCTA = mapCTA(bookCTA?.secondaryCTA as CTABuilderType, url)

  const mappedFilters = {
    title: bookFilters?.bookTitle,
    primarySubtitle: bookFilters?.bookSubtitle,
    price: bookFilters?.bookPrice,
    primaryCTA: bookFilters?.primaryCTA,
    tagline: bookFilters?.bookTagline,
    detail1: bookFilters?.bookPublisher,
    description: bookFilters?.bookMarketingDescription,
    secondaryCTA: bookFilters?.secondaryCTA,
  }

  const bookCard =
    bookVariation === BookVariation.PRODUCT
      ? {
          type: CardTypes.PRODUCT,
          displayFilters: mappedFilters,
          imageStyles: 'bg-white-100',
          data: {
            slug: url,
            size: cardSize,
            title,
            primarySubtitle: subtitle,
            detail1: publisher?.name,
            media: {
              ...media,
              objectFit: MEDIA_OBJECT_FIT.CONTAIN,
            },
            hideImage,
            price,
            compareAtPrice:
              compareAtPrice && price && compareAtPrice > price ? compareAtPrice : undefined,
            primaryCTA,
            secondaryCTA,
            ...safeText({ key: 'description', text: marketingDescription }),
            tagline: tagline
              ? createPortableTextElement({ text: tagline, customStyles: { normal: '!text-sm' } })
              : undefined,
            ...ctaBook,
          },
        }
      : {
          type: CardTypes.CONTENT,
          imageStyles: 'bg-white-100',
          data: {
            slug: url,
            size: cardSize,
            category: publisher?.name,
            media: {
              ...media,
              objectFit: MEDIA_OBJECT_FIT.CONTAIN,
            },
            title,
            subtitle,
            ...safeText({ key: 'description', text: marketingDescription }),
            ...ctaBook,
          },
        }

  return {
    ...bookCard,
    onClickImage: () => {
      gtmProductListingItemClickedEvent(data)
    },
    onViewport: () => {
      gtmProductListingViewedEvent(data)
    },
  }
}
