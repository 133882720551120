import type { LocationSchemaDetailedType } from '@/sanity/queries/common/locationData'

const StreetAddress = ({
  addressLine,
  className,
}: {
  addressLine: string | null | undefined
  className?: string
}) => {
  if (!addressLine) return null
  return (
    <p itemProp="streetAddress" property="streetAddress" className={className}>
      {addressLine}
    </p>
  )
}
const StreetAddress2 = ({ addressLine2 }: { addressLine2: string | null | undefined }) => {
  if (!addressLine2) return null
  return <span>{addressLine2}</span>
}
const RegionAddress = ({ state }: { state: string | null | undefined }) => {
  if (!state) return null
  return (
    <span itemProp="addressRegion" property="addressRegion">
      {state}
    </span>
  )
}
const LocalityAddress = ({ city }: { city: string | null | undefined }) => {
  if (!city) return null
  return (
    <span itemProp="addressLocality" property="addressLocality">
      {city}
    </span>
  )
}
const PostalCode = ({ zipCode }: { zipCode: string | null | undefined }) => {
  if (!zipCode) return null
  return (
    <span itemProp="postalCode" property="postalCode">
      {zipCode}
    </span>
  )
}

export const LocationAddressData = ({ loc }: { loc: LocationSchemaDetailedType }) => {
  const { address, name } = loc ?? {}
  const { city, state, zipCode, addressLine, addressLine2 } = address ?? {}
  if (name.includes('New York')) {
    return (
      <>
        <StreetAddress addressLine={addressLine} className="mb-[5px]" />
        <RegionAddress state={state} />
        {state && city ? ', ' : null}
        <LocalityAddress city={city} />
        <PostalCode zipCode={` ${zipCode}`} />
      </>
    )
  }
  if (name.includes('Los Angeles')) {
    return (
      <>
        <StreetAddress addressLine={addressLine} />
        <LocalityAddress city={city} />
        {state && city ? ', ' : null}
        <RegionAddress state={state} />
        <PostalCode zipCode={` ${zipCode}`} />
      </>
    )
  }
  if (name.includes('London')) {
    return (
      <>
        <StreetAddress addressLine={addressLine} />
        <LocalityAddress city={city} />
        {state && city ? ', ' : null}
        <RegionAddress state={state} />
        <PostalCode zipCode={` ${zipCode}`} />
      </>
    )
  }
  if (name.includes('Paris')) {
    return (
      <>
        <StreetAddress addressLine={addressLine} />
        <PostalCode zipCode={zipCode} />
        <LocalityAddress city={` ${city}`} />
        {state && city ? ', ' : null}
        <RegionAddress state={state} />
      </>
    )
  }
  if (name.includes('Hong Kong')) {
    return (
      <>
        <StreetAddress addressLine={addressLine} />
        <StreetAddress2 addressLine2={addressLine2} />
        {addressLine2 && city ? ', ' : null}
        <LocalityAddress city={city} />
      </>
    )
  }
  return null
}
