import { MediaIcons } from '@zwirner/design-system'
import Image from 'next/image'

import { FAIR, FAIRS_URL, LEARN_MORE, LISTEN_NOW, VIEW_MORE } from '@/common/constants/commonCopies'
import {
  ctaMapperByMoleculeType,
  MappedCTATypes,
  simpleMoleculeMapper,
} from '@/common/utilsMappers/cta.mapper'
import { mapSingleDateFormat, mapStatus } from '@/common/utilsMappers/date.mapper'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { createPortableTextElement, safeText } from '@/common/utilsMappers/safe'
import { cardContentArticle } from '@/components/pageBuilder/utils/commonMappers'
import { MediaBuilderTypes } from '@/sanity/queries/components/builders/mediaBuilder'
import type { FieldsPerTypeSchemaType } from '@/sanity/queries/components/componentTypesData'
import type { BookContentType } from '@/sanity/queries/components/content/bookContent'
import type { DzHeroSchemaProps } from '@/sanity/queries/components/dzHeroProps'
import { capitalizeFirstLetter } from '@/utils/string/capitalizeFirstLetter'

import { PageBuilderComponents } from '../types'
import { exhibitionMapper } from './common'

const heroSimpleCTAMapper = simpleMoleculeMapper(PageBuilderComponents.dzHero)
const heroCTAMapper = ctaMapperByMoleculeType(PageBuilderComponents.dzHero)

const getMoleculeOverrides = (ctaOptions: { primaryCTA: any; secondaryCTA: any }) => {
  const overridePrimary = heroCTAMapper({ data: ctaOptions?.primaryCTA, options: { asLink: true } })
  const overrideSecondary = heroCTAMapper({
    data: ctaOptions?.secondaryCTA,
    options: { asLink: false },
  })
  return { ...overridePrimary, ...overrideSecondary }
}
export const dzHeroOverrides = (props: DzHeroSchemaProps) => {
  const {
    headingOverride,
    subHeadingOverride,
    secondaryTitleOverride,
    descriptionOverride,
    imageOverride,
    enableOverrides,
  } = props
  if (!enableOverrides) return {}

  const mediaMapper = imageOverride
    ? dzMediaMapper({
        data: { image: imageOverride },
        ImgElement: Image,
        extraImgProps: {
          sizes: '100vw',
        },
      })
    : null

  const title = headingOverride ? { title: headingOverride } : {}
  const subtitle = subHeadingOverride ? { subtitle: subHeadingOverride } : {}
  const secondaryTitle = secondaryTitleOverride ? { secondaryTitle: secondaryTitleOverride } : {}
  const description = descriptionOverride ? { description: descriptionOverride } : {}

  return {
    media: mediaMapper?.media ?? undefined,
    category: props.categoryOverride,
    ...title,
    ...subtitle,
    ...secondaryTitle,
    ...description,
  }
}

export const contentTypesMapper: any = {
  article: (data: FieldsPerTypeSchemaType, props: any) => {
    if (data._type !== 'article') throw new Error('Invalid data type')
    const cardProps = cardContentArticle({
      data: {
        ...data,
        title: data.title || '',
      },
      props,
    })
    const { ctaOptions } = data
    const ctaOverrides = getMoleculeOverrides(ctaOptions)
    return { ...cardProps, ...ctaOverrides }
  },
  artist: (data: any) => {
    const { birthdate, fullName, deathDate, picture, summary, description, ctaOptions } = data
    const { media } = dzMediaMapper({
      data: { image: picture },
      ImgElement: Image,
      extraImgProps: {
        sizes: '100vw',
      },
    })
    const ctaOverrides = getMoleculeOverrides(ctaOptions)
    return {
      media,
      title: fullName,
      subtitle: `${birthdate} ${deathDate ? ` // ${deathDate}` : ''}`,
      secondaryTitle: summary,
      description,
      ...ctaOverrides,
    }
  },
  artwork: (data: any) => {
    const { availability, dimensions, edition, medium, title, ctaOptions } = data
    const { media } = dzMediaMapper({
      data,
      ImgElement: Image,
      extraImgProps: {
        sizes: '100vw',
      },
    })
    const ctaOverrides = getMoleculeOverrides(ctaOptions)

    return {
      media,
      category: availability,
      title,
      description: `${dimensions} ${edition} ${medium}`,
      ...ctaOverrides,
    }
  },
  book: (data: DzHeroSchemaProps & BookContentType) => {
    const marketingDescription = safeText({
      key: 'description',
      text: data.marketingDescription || '',
    })
    const { ctaOptions, slug } = data
    const ctaOverrides = ctaOptions ? getMoleculeOverrides(ctaOptions) : {}
    const { media } = dzMediaMapper({
      data,
      ImgElement: Image,
      extraImgProps: {
        sizes: '100vw',
      },
    })

    const linkCTA = { text: VIEW_MORE, url: slug?.current }

    return {
      media,
      title: data.title,
      category: 'Book',
      subtitle: data.subtitle,
      ...marketingDescription,
      ...ctaOverrides,
      linkCTA,
    }
  },
  exhibitionPage: (data: FieldsPerTypeSchemaType) => {
    if (data._type !== 'exhibitionPage') throw new Error('Invalid data type')
    return exhibitionMapper({ data })
  },
  onlineExhibitionPage: (data: FieldsPerTypeSchemaType) => {
    if (data._type !== 'onlineExhibitionPage') throw new Error('Invalid data type')
    return exhibitionMapper({ data })
  },
  exceptionalWork: (data: FieldsPerTypeSchemaType) => {
    if (data._type !== 'exceptionalWork') throw new Error('Invalid data type')
    return exhibitionMapper({ data })
  },
  fairPage: (data: FieldsPerTypeSchemaType) => {
    if (data._type !== 'fairPage') throw new Error('Invalid data type')

    const { heroMedia, mediaOverride, slug, enableOverrides, ctaOptions, cardViewMedia } = data
    const { status } = mapStatus(data)

    const heroMediaSource =
      Object.keys(heroMedia ?? {}).length > 0 && heroMedia?.type !== MediaBuilderTypes.UNSET
        ? heroMedia
        : null

    const cardViewMediaSource =
      Object.keys(cardViewMedia ?? {}).length > 0 && cardViewMedia?.type !== MediaBuilderTypes.UNSET
        ? cardViewMedia
        : null

    const { media } = dzMediaMapper({
      data:
        (enableOverrides ? mediaOverride : null) ?? cardViewMediaSource ?? heroMediaSource ?? data,
      ImgElement: Image,
      extraImgProps: {
        sizes: '100vw',
      },
    })
    const summaryText = safeText({ key: 'description', text: data.summary || '' })
    const subtitle =
      data.boothOrStand && data.boothOrStandNumber
        ? capitalizeFirstLetter(`${data.boothOrStand}: ${data.boothOrStandNumber}`)
        : ''
    const link = (data.externalUrlToggle ? data.externalUrl : slug?.current) ?? FAIRS_URL
    const linkCTAText = data.externalUrlToggle
      ? `${LEARN_MORE} at ${new URL(data.externalUrl || FAIRS_URL).hostname.replace('www.', '')}`
      : LEARN_MORE

    const ctaOverrides = getMoleculeOverrides(ctaOptions)
    return {
      media,
      category: data.eyebrow ?? FAIR,
      title: data.title,
      subtitle,
      secondaryTitle: data.location?.name,
      secondarySubtitle: status,
      ...summaryText,
      linkCTA: {
        text: linkCTAText,
        url: link,
        linkProps: {
          openNewTab: data.externalUrlToggle,
        },
      },
      ...ctaOverrides,
    }
  },
  podcast: (data: FieldsPerTypeSchemaType) => {
    if (data._type !== 'podcast') throw new Error('Invalid data type')
    const { title, eyebrow, subtitle, dateSelection, description, itunesUrl, image, cta } = data
    const date = mapSingleDateFormat(dateSelection)

    const { media } = dzMediaMapper({
      data: image,
      ImgElement: Image,
      options: {
        imgIcon: MediaIcons.Microphone,
      },
    })
    const mediaToRender = media?.imgProps?.src ? media : null

    const ctaOfPodcast = heroCTAMapper({ data: cta, options: { asLink: true } })
    const ctaOverrides = heroSimpleCTAMapper({
      type: MappedCTATypes.LINK,
      supported: true,
      text: LISTEN_NOW,
      url: itunesUrl ? itunesUrl : undefined,
      openNewTab: true,
    })

    return {
      media: mediaToRender,
      category: eyebrow,
      title,
      subtitle,
      secondarySubtitle: date,
      portableTextDescription: description
        ? createPortableTextElement({
            text: description,
          })
        : undefined,
      ...ctaOverrides,
      ...ctaOfPodcast,
    }
  },
}
