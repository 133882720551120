type DeepMerge = Record<string, unknown> | Array<unknown> | unknown | undefined | null

/**
 * Deep merge two objects, with the second object taking priority over the first.
 *
 * It takes null values into account but not undefined values.
 * Example: deepMerge({a: undefined, b: null}, {a: null, c: undefined}) => {a: null, b: null, c: undefined}
 *
 * @param source Source object
 * @param target Target object (takes priority over source)
 * @param maxDepth The maximum depth to deep merge
 * @param depth The current depth of the merge
 */
export const deepMerge = <P extends DeepMerge, T extends DeepMerge>(
  source: P,
  target: T,
  maxDepth: number = 5,
  depth: number = 0
): T & P => {
  // Don't deep merge if we've reached max depth
  if (depth === maxDepth) return target as T & P

  // Don't deep merge undefined
  if (source === undefined) return target as T & P
  if (target === undefined) return source as T & P

  // If either source or target is null, return target
  if (source === null || target === null) return target as T & P

  // Don't deep merge arrays
  if (Array.isArray(source) || Array.isArray(target)) return target as T & P

  // Don't deep merge strings, numbers, booleans, or functions
  if (typeof source !== 'object' || typeof target !== 'object') return target as T & P

  // Don't deep merge react elements
  if ((source && '$$typeof' in source) || (target && '$$typeof' in target)) return target as T & P

  // Traverse all keys in source and target, and merge their values
  return [...Object.keys(source || {}), ...Object.keys(target || {})].reduce((prev, key) => {
    const sourceValue = (source as Record<string, unknown>)?.[key]
    const targetValue = (target as Record<string, unknown>)?.[key]

    if (typeof sourceValue === 'object' || typeof targetValue === 'object')
      return {
        ...prev,
        [key]: deepMerge(sourceValue, targetValue, maxDepth, depth + 1),
      }

    // If values are not objects, target has priority
    return { ...prev, [key]: targetValue === undefined ? sourceValue : targetValue }
  }, {}) as T & P
}
