import { DzText } from '@zwirner/design-system'

import { DzPortableText } from '@/components/wrappers/DzPortableTextWrapper'
import type { LocationSchemaDetailedType } from '@/sanity/queries/common/locationData'

type Props = {
  specialHours: LocationSchemaDetailedType['specialHours']
}

export const SpecialHoursBlock = ({ specialHours }: Props) => {
  if (!specialHours || !specialHours.length) return null
  return (
    <div>
      <DzText textSize="medium" className="mb-2.5 text-black-60" text="Special Hours" />
      <DzPortableText
        portableProps={{ value: specialHours }}
        customStyles={{ normal: 'text-sm md:text-md' }}
      />
    </div>
  )
}
