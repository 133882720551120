import { CardSizes } from '@zwirner/design-system'
import { useContext } from 'react'

import { MediaTypeContext } from '@/components/containers/exhibitions/exhibitionViewsContainer/MediaTypeProvider'
import { DzCard } from '@/components/wrappers/DzCardWrapper'
import type { DzMediaSchemaProps } from '@/sanity/types'

import { contentTypesMapper, dzMediaOverrides } from './mediaMapper'

type DzMediaProps = {
  data: any
  componentProps: DzMediaSchemaProps
}

export const DzMedia = ({ data, componentProps }: DzMediaProps) => {
  const { _type } = data ?? {}
  const cardSize = componentProps?.cardSize ?? CardSizes['12col']
  const mediaType = useContext(MediaTypeContext)
  const mappedData =
    (contentTypesMapper[_type] ?? ((a: any) => a))(data, { ...(componentProps ?? {}) }, cardSize) ??
    {}
  const overrideData = dzMediaOverrides(componentProps, mediaType) ?? {}

  return (
    <div>
      <DzCard {...{ ...mappedData, ...overrideData }} />
    </div>
  )
}

DzMedia.notContentDependant = true
export default DzMedia
