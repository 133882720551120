import type { PropsWithChildren } from 'react'

import cn from '@/utils/cn'

type Props = {
  /** optional style overrides; css specificity requires prefixing with element type, e.g.
   *  div.noGapContainer { gap: 0 } */
  className?: string
} & PropsWithChildren

export const FullWidthFlexCol = ({ children, className }: Props) => {
  return (
    <div
      className={cn(
        'mb-[1.875rem] flex w-full flex-col gap-[3.75rem] md:mb-[3.75rem] md:gap-30',
        className
      )}
    >
      {children}
    </div>
  )
}
