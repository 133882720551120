import {
  DzColumn,
  DzGridColumns,
  DzText,
  DzTitle,
  MEDIA_ASPECT_RATIOS,
  TITLE_SIZES,
  TITLE_TYPES,
} from '@zwirner/design-system'
import Image from 'next/image'

import { LocationAddressData } from '@/common/components/locations/LocationAddressData'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { DzMedia } from '@/components/wrappers/DzMediaWrapper'
import type { LocationSchemaDetailedType } from '@/sanity/queries/common/locationData'
import { MediaBuilderTypes } from '@/sanity/queries/components/builders/mediaBuilder'
import cn from '@/utils/cn'

import { ContactBlock } from './ContactBlock'
import { HoursBlock } from './HoursBlock'
import { SpecialHoursBlock } from './SpecialHoursBlock'

export type Props = {
  data: {
    location: LocationSchemaDetailedType
    title?: string | null
    subtitle?: string | null
    media: any
  }
  isVisible: boolean
}

export const GalleryHero = ({ data, isVisible }: Props) => {
  const { location, title, subtitle } = data

  const cardMedia = data.media?.type !== MediaBuilderTypes.UNSET ? data.media : null
  const locationMedia = location.photos?.[0]

  const { media } = dzMediaMapper({
    data: cardMedia || locationMedia,
    ImgElement: Image,
    options: {
      aspectRatio: MEDIA_ASPECT_RATIOS['AUTO'],
      imageContainerClassName: 'aspect-[5/4]',
    },
  })

  return (
    <div
      className={cn('mb-[3.75rem] md:!mb-30', {
        ['hidden']: !isVisible,
      })}
    >
      <DzGridColumns>
        {title ? (
          <DzColumn span={4} className="self-center py-10 md:py-0">
            <DzTitle
              title={title}
              subtitle={subtitle}
              titleType={TITLE_TYPES.H3}
              titleSize={TITLE_SIZES.XXL}
              subtitleSize={TITLE_SIZES.XXL}
            />
          </DzColumn>
        ) : null}
        <DzColumn className="aspect-[5/4]" span={8} start={5}>
          <DzMedia {...media} isFetchPriority={isVisible} />
        </DzColumn>
      </DzGridColumns>
      <DzGridColumns className="mt-10 md:mt-[3.75rem]">
        <div className="col-span-12 grid grid-cols-1 gap-x-5 gap-y-10 md:col-span-8 md:col-start-5 md:grid-cols-2 md:gap-y-[3.75rem]">
          <div>
            <DzText text="Location" textSize="medium" className="mb-2.5 text-black-60" />
            <address className="underline-of text-sm not-italic underline decoration-black-40 decoration-1 underline-offset-[0.375rem] md:text-md">
              <LocationAddressData loc={location} />
            </address>
          </div>
          <ContactBlock phone={location.phone} email={location.email} />
          <HoursBlock hours={location.hours} />
          <SpecialHoursBlock specialHours={location.specialHours} />
        </div>
      </DzGridColumns>
    </div>
  )
}
