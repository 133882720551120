export enum PageBuilderComponents {
  dzCard = 'dzCard',
  dzCarousel = 'dzCarousel',
  dzEditorial = 'dzEditorial',
  dzGrid = 'grid',
  dzHero = 'dzHero',
  dzInterstitial = 'dzInterstitial',
  dzMedia = 'dzMedia',
  dzSplit = 'dzSplit',
  oneUp = 'oneUp',
  dzLanding = 'dzLanding',
  dzGalleryHero = 'dzGalleryHero',
  dzImageMosaic = 'dzImageMosaic',
}

export type PageBuilderNames = Exclude<
  (typeof PageBuilderComponents)[keyof typeof PageBuilderComponents],
  'oneUp'
>
