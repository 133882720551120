import { FetchPriorityContext } from '@zwirner/design-system'
import { useContext } from 'react'

import { GalleryHero } from '@/components/containers/galleries/components/GalleryHero'
import type { DzGalleryHeroPropsDataType } from '@/sanity/queries/components/dzGalleryHeroProps'

type DzGalleryHeroProps = {
  data: any
  componentProps: DzGalleryHeroPropsDataType
}

export const DzGalleryHero = ({ componentProps }: DzGalleryHeroProps) => {
  const { isFetchPriority } = useContext(FetchPriorityContext)
  return <GalleryHero data={componentProps} isVisible={isFetchPriority} />
}

DzGalleryHero.notContentDependant = true
