import { DzText, LINK_VARIANTS, TEXT_TYPES } from '@zwirner/design-system'

import { DzLink } from '@/components/wrappers/DzLinkWrapper'
import type { LocationSchemaDetailedType } from '@/sanity/queries/common/locationData'

type Props = {
  phone: LocationSchemaDetailedType['phone']
  email: LocationSchemaDetailedType['email']
}

const PhoneLine = ({ phone }: Omit<Props, 'email'>) => {
  if (!phone) return null
  return (
    <div className="mb-[5px]">
      <DzText textType={TEXT_TYPES.SPAN} text="Phone:" textSize="medium" className="mr-2.5" />
      <DzLink href={`tel:${phone}`} variant={LINK_VARIANTS.TEXT} className="text-sm md:text-md">
        {phone}
      </DzLink>
    </div>
  )
}
const EmailLine = ({ email }: Omit<Props, 'phone'>) => {
  if (!email) return null
  return (
    <div>
      <DzText textType={TEXT_TYPES.SPAN} text="Email:" textSize="medium" className="mr-2.5" />
      <DzLink href={`mailto:${email}`} variant={LINK_VARIANTS.TEXT} className="text-sm md:text-md">
        {email}
      </DzLink>
    </div>
  )
}

export const ContactBlock = ({ phone, email }: Props) => {
  if (!phone && !email) return null
  return (
    <div>
      <DzText textSize="medium" className="mb-2.5 text-black-60" text="Contact"></DzText>
      <PhoneLine phone={phone} />
      <EmailLine email={email} />
    </div>
  )
}
