import { EditorialType, MEDIA_TYPES } from '@zwirner/design-system'
import Image from 'next/image'

import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { safeText } from '@/common/utilsMappers/safe'
import { builder } from '@/sanity/imageBuilder'
import type { DzEditorialSchemaProps } from '@/sanity/types'
import { EditorialTextTypes } from '@/sanity/types'

const DEFAULT_HEADER_HEIGHT = 70

const getTextFromOverride = <T extends { text: unknown; textType: unknown }[]>(override: T) => {
  return override?.map((t) => {
    const { text, textType: type } = t
    return { text, type }
  })
}

export const dzEditorialOverrides = (props: DzEditorialSchemaProps) => {
  const {
    editorialType,
    editorialTextOverrides,
    editorialTextOverridesSimple = [],
    editorialTextOverridesLeftBlock = [],
    editorialTextOverridesCentered = [],
    quoteTitle,
    quoteFootNote,
    reverse = false,
    imageOverride,
  } = props ?? {}

  if (editorialType === EditorialType.QUOTE) {
    const quoteTitleText = safeText({
      text: quoteTitle ?? '',
      key: 'quote',
      customStyles: {
        normal: 'text-lg md:text-xxl',
      },
    })

    const quoteDetailText = safeText({
      text: quoteFootNote ?? '',
      key: 'quoteDetail',
      customStyles: {
        normal: 'text-sm md:text-md',
      },
    })

    return {
      type: editorialType,
      data: {
        ...quoteTitleText,
        ...quoteDetailText,
      },
    }
  }

  const isComplexType = editorialType === EditorialType.COMPLEX
  let editorialData: NonNullable<DzEditorialSchemaProps['editorialTextOverrides']> = []

  switch (editorialType) {
    case EditorialType.SIMPLE:
      editorialData = [
        { textType: EditorialTextTypes.PARAGRAPH, text: editorialTextOverridesSimple },
      ]
      break
    case EditorialType.CENTERED:
      editorialData = editorialTextOverridesCentered ?? []
      break
    case EditorialType.COMPLEX:
      editorialData = editorialTextOverrides ?? []
      break
    case EditorialType.LEFT_BLOCK:
      editorialData = editorialTextOverridesLeftBlock ?? []
      break
  }

  const paragraphs = editorialData
    .filter((item) => {
      if (item.textType === EditorialTextTypes.QUOTE) {
        return Boolean(item.pullQuoteTitle) || Boolean(item.pullQuoteAttribution)
      }

      // in some cases, the text is null
      if (item.textType === EditorialTextTypes.PARAGRAPH) {
        return Boolean(item.text)
      }
    })
    .map(({ textType, text, pullQuoteTitle, pullQuoteAttribution }) => {
      if (textType === EditorialTextTypes.QUOTE) {
        const pullQuoteTitleText = safeText({
          text: pullQuoteTitle ?? '',
          key: 'pullQuoteTitle',
          customStyles: {
            normal: 'text-lg md:text-xl',
          },
        })

        const pullQuoteAttributionText = safeText({
          text: pullQuoteAttribution ?? '',
          key: 'pullQuoteAttribution',
          customStyles: {
            normal: 'text-sm md:text-md',
          },
        })

        const id = (pullQuoteTitle || pullQuoteAttribution)!.reduce(
          (acc, { _key }) => `${acc}${_key}`,
          ''
        )

        return {
          id,
          type: EditorialTextTypes.QUOTE,
          ...pullQuoteTitleText,
          ...pullQuoteAttributionText,
        }
      }

      const id = text ? text.reduce((acc, { _key }) => `${acc}${_key}`, '') : ''

      return {
        ...safeText({ key: 'text', text: text ?? '', bottomIndent: true }),
        id,
        type: EditorialTextTypes.PARAGRAPH,
      }
    })

  if (isComplexType) {
    const media =
      imageOverride
        ?.map((data) => {
          const { media, extras } = dzMediaMapper({ data: data.media, ImgElement: Image })
          if (!extras?.caption) return { media }

          const description = safeText({
            text: extras.caption,
            key: 'description',
            customStyles: {
              normal: 'text-sm md:text-sm text-black-60',
            },
          })
          return { media, ...description }
        })
        .filter((i) => Boolean(i.media)) || []

    return {
      type: editorialType,
      data: {
        paragraphs,
        reverse,
        media,
        stickyColumnOffset: DEFAULT_HEADER_HEIGHT,
      },
    }
  }

  return {
    type: editorialType,
    data: {
      paragraphs,
    },
  }
}

export const contentTypesMapper = {
  press: (data: any) => {
    const { editorialType, authors, summary, title, editorialTextOverrides } = data
    const [mainAuthor] = authors ?? []
    const { picture } = mainAuthor ?? {}
    const { asset, alt } = picture ?? {}
    const imgSrc = asset ? builder.image(asset).url() : ''
    return {
      type: editorialType,
      data: {
        media: {
          url: '/',
          type: MEDIA_TYPES.IMAGE,
          imgProps: {
            src: imgSrc,
            alt,
          },
        },
        reverse: true,
        paragraphs: getTextFromOverride(editorialTextOverrides),
        quote: summary,
        quoteDetail: title,
      },
    }
  },
  book: (data: any) => {
    const { editorialType, editorialTextOverrides, photos, summary, title } = data
    const [mainPicture] = photos ?? []
    const { asset, alt } = mainPicture ?? {}
    const imgSrc = asset ? builder.image(asset).url() : ''

    return {
      type: editorialType,
      data: {
        media: {
          url: '/',
          type: MEDIA_TYPES.IMAGE,
          imgProps: {
            src: imgSrc,
            alt,
          },
        },
        reverse: true,
        paragraphs: getTextFromOverride(editorialTextOverrides),
        quote: summary,
        quoteDetail: title,
      },
    }
  },
}
