import { CardSizes } from '@zwirner/design-system'
import { useContext } from 'react'

import { DzCard as DzCardMolecule } from '@/components/wrappers/DzCardWrapper'
// import useEcommActions from '@/components/hooks/useEcommActions'
import type { DzCardExtendedProps } from '@/sanity/types'
import { deepMerge } from '@/utils/object/deepMerge'

import { GridContainerContext } from '../context'
import type { ContentTypesMapperKeys } from './cardMapper'
import { contentTypesMapper } from './cardMapper'
import { customCardMapper } from './customCardMapper'
import { dzCardOverrides } from './overrides'

type DzCardProps = {
  data: any
  componentProps: DzCardExtendedProps
}

export const processDzCardData = ({
  data,
  componentProps,
  isInGrid = false,
}: DzCardProps & { isInGrid?: boolean }) => {
  const { _type: type } = data ?? {}
  const isCustomCard = componentProps.discriminator === 'dzCustomCard'
  const cardSize = componentProps?.cardSize ?? CardSizes['12col']

  if (isCustomCard) return customCardMapper(componentProps, { cardSize })

  const mapped = contentTypesMapper[type as ContentTypesMapperKeys]?.(data, {
    ...(componentProps ?? {}),
    cardSize,
    isInGrid,
  })

  if (!mapped) throw new Error('Invalid card type')

  const override = dzCardOverrides(
    { ...(componentProps || {}), cardSize, isInGrid },
    mapped.type,
    data
  )

  // Max depth 3: data.media.imgProps
  const merged = deepMerge(mapped, override, 3)

  return merged
}

export const DzCard = ({ data, componentProps }: DzCardProps) => {
  // const {handleLineAdd} = useEcommActions()
  const { isInGrid } = useContext(GridContainerContext)
  return (
    <DzCardMolecule
      {...processDzCardData({
        data: { ...data },
        componentProps: { ...componentProps },
        isInGrid,
      })}
    />
  )
}
