import type { CardSizes } from '@zwirner/design-system'
import { CardTypes } from '@zwirner/design-system'
import Image from 'next/image'

import type { CTAExtended } from '@/common/utilsMappers/cta.mapper'
import { ctaMapper } from '@/common/utilsMappers/cta.mapper'
import { dzMediaMapper, getSizesByCardSize } from '@/common/utilsMappers/image.mapper'
import { createPortableTextElement } from '@/common/utilsMappers/safe'
import type { DzCardExtendedProps } from '@/sanity/types'

import { contentLinkCTAMapper, valueOrUndefined } from './utils'

export const customCardMapper = (
  props: Extract<DzCardExtendedProps, { discriminator: 'dzCustomCard' }>,
  { cardSize }: { cardSize: CardSizes }
) => {
  const {
    customCardTitle,
    customCardSubtitle,
    customCardDescription,
    customCardEyebrow,
    customCardSecondaryTitle,
    customCardSecondarySubtitle,
    customCardMedia,
    customCardCta,
    customCardLinkCTA,
  } = props

  const { media, hideImage } = dzMediaMapper({
    data: customCardMedia,
    ImgElement: Image,
    extraImgProps: {
      sizes: getSizesByCardSize(cardSize),
    },
  })

  const customCTA =
    customCardCta !== null
      ? ctaMapper({
          data: {
            primaryCTA: customCardCta as CTAExtended,
          },
          props: {
            linkAsButton: true,
            hideSecondary: true,
          },
        })
      : {}

  const linkCTA = contentLinkCTAMapper(customCardLinkCTA)

  return {
    data: {
      size: cardSize,
      media,
      hideImage,
      category: valueOrUndefined(customCardEyebrow),
      title: valueOrUndefined(customCardTitle),
      subtitle: valueOrUndefined(customCardSubtitle),
      secondaryTitle: valueOrUndefined(customCardSecondaryTitle),
      secondarySubtitle: valueOrUndefined(customCardSecondarySubtitle),
      portableTextDescription: customCardDescription
        ? createPortableTextElement({ text: customCardDescription })
        : undefined,
      ...customCTA,
      ...linkCTA,
    },
    type: CardTypes.CONTENT,
  }
}
