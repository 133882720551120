import { CardTypes, TITLE_TYPES } from '@zwirner/design-system'
import Image from 'next/image'

import {
  EXHIBITION,
  EXHIBITIONS_URL,
  FAIR,
  FAIRS_URL,
  LEARN_MORE,
  ONLINE,
  ONLINE_EXHIBITION_TITLE,
} from '@/common/constants/commonCopies'
import { mapStatus } from '@/common/utilsMappers/date.mapper'
import { dzMediaMapper, getSizesByCardSize } from '@/common/utilsMappers/image.mapper'
import { safeText } from '@/common/utilsMappers/safe'
import { MediaBuilderTypes } from '@/sanity/queries/components/builders/mediaBuilder'
import type { FieldsPerTypeSchemaType } from '@/sanity/queries/components/componentTypesData'
import type { DzCardExtendedProps } from '@/sanity/types'
import { capitalizeFirstLetter } from '@/utils/string/capitalizeFirstLetter'

export const exhibitionMapper = ({
  data,
  props,
}: {
  data: Extract<
    FieldsPerTypeSchemaType,
    { _type: 'exhibitionPage' | 'onlineExhibitionPage' | 'exceptionalWork' }
  >
  props: DzCardExtendedProps
}) => {
  const { cardSize } = props ?? {}
  const { subtitle, title, heroMedia, cardViewMedia, slug } = data

  const heroMediaSource =
    Object.keys(heroMedia ?? {}).length > 0 && heroMedia?.type !== MediaBuilderTypes.UNSET
      ? heroMedia
      : null
  const cardViewMediaSource =
    Object.keys(cardViewMedia ?? {}).length > 0 &&
    cardViewMedia?.type == MediaBuilderTypes.IMAGE &&
    cardViewMedia?.image
      ? cardViewMedia
      : null

  const { media, hideImage } = dzMediaMapper({
    data: cardViewMediaSource ?? heroMediaSource ?? data,
    ImgElement: Image,
    extraImgProps: {
      sizes: getSizesByCardSize(cardSize),
    },
  })
  const { status } = mapStatus(data)

  let locationName = ONLINE
  if (data._type === 'exhibitionPage') {
    const [primaryLocation] = data.locations ?? []
    const { name } = primaryLocation ?? {}
    locationName = name
  }

  let description: string | any[] = ''
  if (data._type === 'exceptionalWork') description = data.description ?? ''
  if (data._type !== 'exceptionalWork') description = data.summary ?? ''

  const descriptionText = safeText({ key: 'description', text: description })

  let eyebrow = data.eyebrow
  if (data._type === 'exhibitionPage') eyebrow = eyebrow ?? EXHIBITION
  if (data._type !== 'exhibitionPage') eyebrow = eyebrow ?? ONLINE_EXHIBITION_TITLE

  return {
    type: CardTypes.CONTENT,
    data: {
      media,
      isDisabled: false,
      size: cardSize,
      category: eyebrow,
      title,
      subtitle,
      secondaryTitle: locationName,
      secondarySubtitle: status,
      hideImage,
      linkCTA: {
        text: LEARN_MORE,
        url: slug.current ?? EXHIBITIONS_URL,
      },
      ...descriptionText,
    },
  }
}

export const fairMapper = ({
  data,
  props,
}: {
  data: Extract<FieldsPerTypeSchemaType, { _type: 'fairPage' }>
  props: DzCardExtendedProps
}) => {
  const { cardSize } = props ?? {}
  const { title, heroMedia, location, summary, slug, eyebrow, cardViewMedia } = data
  const { name: place } = location ?? {}
  const { current } = slug ?? {}
  const { status } = mapStatus(data)

  const heroMediaSource =
    Object.keys(heroMedia ?? {}).length > 0 && heroMedia?.type !== MediaBuilderTypes.UNSET
      ? heroMedia
      : null

  const cardViewMediaSource =
    Object.keys(cardViewMedia ?? {}).length > 0 && cardViewMedia?.type !== MediaBuilderTypes.UNSET
      ? cardViewMedia
      : null

  const { media, hideImage } = dzMediaMapper({
    data: cardViewMediaSource ?? heroMediaSource ?? data,
    ImgElement: Image,
  })
  const summaryText = safeText({ key: 'description', text: summary || '' })
  const link = (data.externalUrlToggle ? data.externalUrl : current) ?? FAIRS_URL

  const subtitle =
    data.boothOrStand && data.boothOrStandNumber
      ? capitalizeFirstLetter(`${data.boothOrStand}: ${data.boothOrStandNumber}`)
      : ''

  const linkCTAText = data.externalUrlToggle
    ? `${LEARN_MORE} at ${new URL(data.externalUrl || FAIRS_URL).hostname.replace('www.', '')}`
    : LEARN_MORE

  return {
    type: CardTypes.CONTENT,
    data: {
      media,
      size: cardSize,
      title,
      subtitle,
      category: eyebrow ?? FAIR,
      titleType: TITLE_TYPES.H2,
      secondaryTitle: place,
      secondarySubtitle: status,
      hideImage,
      linkCTA: {
        text: linkCTAText,
        url: link,
        linkProps: {
          openNewTab: data.externalUrlToggle,
        },
      },
      ...summaryText,
    },
  }
}
