import type { ReactNode } from 'react'
import { createContext } from 'react'

import { INSTALLATION_VIEWS } from '@/common/constants/commonCopies'

export const MediaTypeContext = createContext<string | null>(null)

export const MediaTypeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <MediaTypeContext.Provider value={INSTALLATION_VIEWS}>{children}</MediaTypeContext.Provider>
  )
}
