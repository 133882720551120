import type { ArtworkDataType } from '@/sanity/queries/artworks/artworkData'
import type { BookContentType } from '@/sanity/queries/components/content/bookContent'
import type { GtmPageLoadDataType } from '@/sanity/queries/gtm/pageLoad.queries'
import type { GtmCommonDataType } from '@/sanity/queries/gtm/validationPrimitives'
import usePageStore from '@/store/pageStore'

type MapEcommerceData = (
  ecommObject: BookContentType | ArtworkDataType,
  extra?: {
    currency?: boolean
    item_list_id?: boolean
    item_list_name?: boolean
    items?: { item_list_id?: boolean; item_list_name?: boolean; price?: boolean }
  }
) => DataLayerEcommerceProps

const mapperByEcommType: Record<string, MapEcommerceData> = {
  artwork: (artwork, extra) => {
    const artworkData = artwork as ArtworkDataType
    const { title, hash, section } = usePageStore.getState()
    const ecommerce: DataLayerEcommerceProps = {
      value: artworkData.price || 0,
      items: [
        {
          affiliation: 'DZW',
          artwork_ecommerce: artworkData.product?.variants[0].store?.inventory?.isAvailable
            ? 'yes'
            : 'no',
          artwork_frame: artworkData.framed,
          artwork_price: artworkData.price?.toString() || '',
          artwork_size: artworkData.dimensions
            ? artworkData.dimensions[0]?.children[0]?.text || ''
            : '',
          artwork_status: artworkData.product?.variants[0].store?.inventory?.isAvailable
            ? 'available'
            : 'sold',
          item_brand: artwork.artists?.at(0)?.fullName || '',
          item_category: title,
          item_category2: section,
          item_category3: artworkData.artworkType,
          item_category4: artworkData.medium || '',
          item_category5: artworkData._createdAt,
          item_id: artworkData.inventoryId || '',
          item_name: artworkData.title,
          item_variant: artworkData.dateSelection?.year ?? '',
        },
      ],
    }

    if (extra?.item_list_id) ecommerce.item_list_id = hash
    if (extra?.item_list_name) ecommerce.item_list_name = title
    if (extra?.currency) ecommerce.currency = artworkData.currency || ''
    if (extra?.items?.item_list_id && ecommerce.items[0]) ecommerce.items[0].item_list_id = hash
    if (extra?.items?.item_list_name && ecommerce.items[0])
      ecommerce.items[0].item_list_name = title
    if (extra?.items?.price && ecommerce.items[0])
      ecommerce.items[0].price = artworkData.product?.variants[0].store?.price || 0

    return ecommerce
  },
  book: (book) => {
    const bookData = book as BookContentType
    const { title, hash } = usePageStore.getState()
    const ecommerce: BookEcommProps = {
      value: bookData.price || 0,
      // Required Post GoSC
      item_list_id: hash,
      item_list_name: title,
      items: [
        {
          affiliation: 'DZ Books',
          item_list_id: hash,
          item_brand: bookData.artists?.at(0)?.fullName || '',
          item_category: bookData?.publisher?.name || '',
          item_id: bookData.isbn || '',
          item_name: bookData.title,
          item_variant: bookData.yearSelection || '',
        },
      ],
    }

    return ecommerce
  },
}
export const mapEcommerceData: MapEcommerceData = (data, extra) => {
  const { _type } = data
  const ecommMapper = mapperByEcommType[_type]
  return ecommMapper(data, extra)
}

declare global {
  type DataLayerProps = {
    event: GtmCommonDataType['event']
    detailed_event: GtmCommonDataType['detailed_event']
    page_data?: DataLayerPageDataProps
    user_data?: DataLayerUserDataProps
    event_data?: DataLayerEventDataProps
    ecommerce?: DataLayerEcommerceProps
  }

  type DataLayerPageDataProps = GtmPageLoadDataType['page_data'] & { user_login_state?: string }

  type DataLayerUserDataProps = GtmPageLoadDataType['user_data'] | { event_form_hashemail: string }

  type ArtworkEcommProps = {
    count_checkout_payment_step_completions?: number
    coupon?: string
    currency?: 'EUR' | 'USD' | 'GBP' | 'HKD' | ''
    facets?: string
    item_list_id?: string
    item_list_name?: string
    items: {
      affiliation: string
      artwork_ecommerce: string
      artwork_frame: string
      artwork_price: string
      artwork_size: string
      artwork_status: string
      item_brand: string
      item_category: string
      item_category2: string
      item_category3: string
      item_category4: string
      item_category5: string
      item_id: string
      item_list_id?: string
      item_list_name?: string
      item_name: string
      item_variant: string
      price?: number
      quantity?: number
    }[]
    order_level_discount?: string
    payment_method?: string
    shipping?: number
    shipping_tier?: string
    tax?: number
    transaction_id?: string
    type?: string
    value: number
  }
  type BookEcommProps = {
    count_checkout_payment_step_completions?: number
    coupon?: string
    currency?: 'EUR' | 'USD' | 'GBP' | 'HKD' | ''
    facets?: string
    item_list_id?: string
    item_list_name?: string
    items: {
      // A product affiliation to designate a supplying company or brick and mortar store location.
      affiliation: string
      // Item Category (context-specific). item_category2 through item_category5 can also be used if the item has many categories.
      item_brand: string
      item_category?: string
      item_category2?: string
      item_category3?: string
      item_category4?: string
      item_category5?: string
      // Item ID (context-specific).The product primary ID (SKU or UPC)
      item_id: string
      item_list_id?: string
      item_list_name?: string
      item_name: string
      item_variant: string
      price?: number
      quantity?: number
    }[]
    order_level_discount?: string
    payment_method?: string
    shipping?: number
    shipping_tier?: string
    tax?: number
    transaction_id?: string
    type?: string
    value: number
  }

  type DataLayerEcommerceProps = ArtworkEcommProps | BookEcommProps

  export type DataLayerEventDataProps = {
    artist?: string
    artwork_collection?: string
    artwork_ecommerce?: string
    artwork_frame?: string
    artwork_id?: string
    artwork_material?: string
    artwork_posteddate?: string
    artwork_price?: number
    artwork_size?: string
    artwork_status?: string
    artwork_title?: string
    artwork_type?: string
    artwork_year?: string
    cta_value?: string
    error_message?: string
    file_extension?: string
    file_name?: string
    identifier?: string
    link_url?: string
    podcast_percent?: number
    podcast_title?: string
    method?: string
    mode?: string
    number_of_results?: number
    name?: string
    search_term?: string
    step_name?: string
    step_number?: string
    type?: string
    scrollThreshold?: number
    scrollUnits?: string
    scrollDirection?: string
  }
}
