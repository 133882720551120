import { DzInterstitial as DzInterstitialMolecule } from '@/components/wrappers/DzInterstitialWrapper'
import type { DzInterstitialTypeProps } from '@/sanity/types'

import { dzInterstitialOverrides, interstitialMap } from './interstitialMapper'

type DzInterstitialProps = {
  data: any
  componentProps?: DzInterstitialTypeProps
}

export const DzInterstitial = ({ data, componentProps }: DzInterstitialProps) => {
  const { _type } = data ?? {}
  const mapper =
    _type in interstitialMap ? interstitialMap[_type as keyof typeof interstitialMap] : null
  const mappedData = mapper ? mapper(data) : { data }
  const overrideData = componentProps ? dzInterstitialOverrides(componentProps) : {}

  return <DzInterstitialMolecule {...{ ...mappedData, ...overrideData }} />
}

DzInterstitial.notContentDependant = true

export default DzInterstitial
