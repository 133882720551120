import { DzButtonNew, MEDIA_ASPECT_RATIOS, useIsSmallWindowSize } from '@zwirner/design-system'
import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'

import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { DzMedia } from '@/components/wrappers/DzMediaWrapper'
import type { MediaBuilderSchemaType } from '@/sanity/queries/components/builders/mediaBuilder'
import cn from '@/utils/cn'

type GalleryLandingProps = {
  elements: {
    title: string
    link: {
      href: string
      blank: boolean
    }
    media: MediaBuilderSchemaType
    _key: string
  }[]
}
const suffix = '-dzlanding-img'

export const GalleryLanding = ({ elements }: GalleryLandingProps) => {
  const [activeImgId, setActiveImgId] = useState<string>(elements[0]._key)
  const wrapper = useRef<HTMLDivElement>(null)
  const slides = useRef<HTMLDivElement>(null)

  const isMobile = useIsSmallWindowSize()
  useEffect(() => {
    if (isMobile) return
    const wrapperElement = wrapper.current as HTMLElement

    const images = wrapperElement.querySelectorAll(`[id$="${suffix}"]`)

    const observerOptions: IntersectionObserverInit = {
      rootMargin: '-50% 0px',
      threshold: 0,
      root: slides.current,
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries.length === elements.length) return
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveImgId(entry.target.id.replace(suffix, ''))
        }

        if (!entry.isIntersecting) {
          const previousSibling = entry.target.previousElementSibling as HTMLElement
          const previousSiblingId = previousSibling?.id.replace(suffix, '')
          setActiveImgId(previousSiblingId)
        }
      })
    }, observerOptions)

    images.forEach((section) => {
      observer.observe(section)
    })

    return () => {
      images.forEach((section) => {
        observer.unobserve(section)
      })
    }
  }, [elements, isMobile])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      slides.current?.scrollTo(0, window.scrollY - (wrapper.current?.offsetTop || 0) - 70)
    })
  }, [])

  useEffect(() => {
    const wrapperElement = wrapper.current as HTMLElement
    const slidesElement = slides.current as HTMLElement

    const galleryLandingContainer = document.querySelector(
      '#galleryLandingContainer'
    ) as HTMLElement
    const containerChild = galleryLandingContainer.querySelector(
      '#galleryLandingStickyContainer'
    ) as HTMLElement
    if (!galleryLandingContainer) throw new Error('GalleryLandingContainer not found')

    const onResize = () => {
      // In case it is being used in Gallery detail pages
      const noLandingVisible = ![
        ...document.querySelectorAll<HTMLElement>('[data-gallery-landing]'),
      ].some((e) => e.offsetParent !== null)

      if (isMobile || noLandingVisible) {
        galleryLandingContainer.style.height = 'auto'
        containerChild.style.top = 'unset'
        return
      }

      if (wrapperElement.offsetParent == null) return

      const wrapperOffsetTop = wrapperElement.offsetTop
      const allSlidesHeight = slidesElement.getBoundingClientRect().height * (elements.length - 1)

      // 130 = 70(header) + 60(galleryLandingContainer padding-bottom)
      galleryLandingContainer.style.height =
        allSlidesHeight + containerChild.getBoundingClientRect().height + 130 + 'px'

      containerChild.style.top = `-${wrapperOffsetTop - 70}px`
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerChild) {
          onResize()
        }
      }
    })

    resizeObserver.observe(containerChild)
    onResize()

    return () => {
      resizeObserver.unobserve(containerChild)
      resizeObserver.disconnect()
    }
  }, [elements, isMobile])

  const onMouseHover = (elIndex: number) => {
    if (isMobile) return

    if (Math.abs((wrapper.current?.getBoundingClientRect().top || 0) - 70) > 10) return

    const offsetTop =
      (wrapper.current?.offsetTop || 0) +
      (slides.current?.getBoundingClientRect().height || 0) * elIndex +
      70

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    })
  }

  return (
    <div className="grid grid-cols-12 gap-5" ref={wrapper} data-gallery-landing>
      <div className="col-span-12 flex h-fit flex-col items-start gap-5 md:col-span-4 md:gap-10">
        {elements.map((element, index) => (
          <DzButtonNew
            asChild
            key={element._key}
            size="xxl"
            variant="link"
            className={cn(
              'whitespace-pre-wrap transition-none duration-200 hover:!text-black-100 focus-visible:text-black-100 active:underline active:decoration-black-100 md:!text-black-60 md:transition-colors',
              {
                '!text-black-100': activeImgId === element._key,
              }
            )}
            onMouseEnter={() => onMouseHover(index)}
          >
            <Link href={element.link.href} target={element.link.blank ? '_blank' : '_self'}>
              {element.title}
            </Link>
          </DzButtonNew>
        ))}
      </div>
      <div className="col-span-8 hidden aspect-[5/4] overflow-hidden md:block" ref={slides}>
        {elements.map((element) => {
          const { media } = dzMediaMapper({
            data: element.media,
            ImgElement: Image,
            options: {
              aspectRatio: MEDIA_ASPECT_RATIOS['AUTO'],
              imageContainerClassName: 'aspect-[5/4]',
            },
          })

          return (
            <DzMedia
              {...media}
              aspectRatio="aspectAuto"
              containerProps={{ id: element._key + suffix }}
              imageContainerClassName="h-full sticky top-0"
              movingImageContainerClassName="h-full !sticky top-0 [&_video]:!object-cover [&_video]:!h-full !flex"
              key={element._key}
            />
          )
        })}
      </div>
    </div>
  )
}
