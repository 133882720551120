import { GalleryLanding } from '@/components/containers/galleries/components/GalleryLanding'
import type { DzLandingPropsDataType } from '@/sanity/queries/components/dzLandingProps'

type DzLandingProps = {
  componentProps: DzLandingPropsDataType
}

export const DzGalleryLanding = ({ componentProps }: DzLandingProps) => {
  return <GalleryLanding elements={componentProps.elements} />
}

DzGalleryLanding.notContentDependant = true
