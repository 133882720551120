import { createContext } from 'react'

export enum GridTypes {
  COMPLEX = 'complex',
  SIMPLE = 'simple',
}

type GridContextType = {
  isInGrid: boolean
  type?: GridTypes
}
type CarouselContextType = {
  isInCarousel: boolean
}

export const GridContainerContext = createContext<GridContextType>({ isInGrid: false })
export const CarouselContainerContext = createContext<CarouselContextType>({ isInCarousel: true })
