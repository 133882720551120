import type { CardSizeType, DzMediaProps } from '@zwirner/design-system'
import { getVideoMedia, MEDIA_TYPES } from '@zwirner/design-system'
import Image from 'next/image'

import type { ARTWORK_BG_COLOR_NAMES } from '@/components/pageBuilder/DzCard/cardMapper'
import { builder } from '@/sanity/imageBuilder'
import { MediaTypes } from '@/sanity/types'

type DzMediaImageMapper = {
  data: any
  url?: string
  ImgElement: typeof Image
  options?: Partial<DzMediaProps>
  extraImgProps?: any
  backgroundColor?: ARTWORK_BG_COLOR_NAMES
}

type DzMediaVideoMapper = {
  data: any
  options?: Partial<DzMediaProps>
  extraVideoProps?: any
}

type DzMediaMapper = (DzMediaImageMapper | DzMediaVideoMapper) & {
  asMediaCard?: boolean
}

const cardSizeToMediaQuery: Record<CardSizeType, string> = {
  '12col': '100vw',
  '10col': '84vw',
  '6col': '50vw',
  '4col': '34vw',
  '3col': '25vw',
  '2col': '17vw',
  1: '9vw',
  2: '17vw',
  3: '25vw',
  4: '34vw',
  5: '42vw',
  6: '50vw',
  7: '59vw',
  8: '67vw',
  9: '75vw',
  10: '84vw',
  11: '92vw',
  12: '100vw',
}

export const getSizesByCardSize = (cardSize: CardSizeType) => {
  return `(max-width: 769px) ${cardSizeToMediaQuery['12col']}, ${cardSizeToMediaQuery[cardSize]}`
}

export const validateImage = (data: any) => {
  const { photos, heroMedia } = data ?? {}
  const { image: heroImage } = heroMedia ?? {}
  const [mainPicture] = photos ?? []
  const { asset, image, url } = mainPicture ?? heroImage ?? {}

  return image && image?.asset ? !!image.asset : (!!asset ?? !!url)
}

export const imageMapper = (data: any) => {
  const {
    photos,
    cardViewMedia,
    heroMedia,
    image: sourceImage,
    asset: srcAsset,
    alt: altAsset,
    caption: captionAsset,
  } = data ?? {}

  const [mainPicture] = photos ?? []
  const { asset, alt, image, caption, url, altText, metadata } =
    cardViewMedia?.image ?? mainPicture?.image ?? mainPicture ?? heroMedia ?? sourceImage ?? {}
  const {
    alt: imageBuilderAlt,
    asset: imageBuilderAsset,
    caption: imageBuilderCaption,
    metadata: imageBuilderMetaData,
  } = image ?? {}
  const pictureAsset = imageBuilderAsset ?? srcAsset ?? asset
  const imgCaption = imageBuilderCaption ?? captionAsset ?? caption
  const imgSrc = pictureAsset ? builder.image(pictureAsset).url() : ''
  const imgAlt = imageBuilderAlt ?? altAsset ?? alt
  const imgMetadata = metadata ?? imageBuilderMetaData
  const lqip = imgMetadata?.lqip
  return {
    alt: altText ?? imgAlt,
    src: pictureAsset ? imgSrc : url,
    caption: imgCaption,
    dimensions: imgMetadata?.dimensions,
    lqip,
  }
}

export const getImageMedia = ({
  data,
  url,
  ImgElement,
  options = {},
  extraImgProps = {},
}: DzMediaImageMapper) => {
  const { src, alt, caption, lqip } = imageMapper(data)
  const blurOptions = lqip
    ? {
        placeholder: 'blur',
        blurDataURL: lqip,
      }
    : {}
  if (!src) return {}
  return {
    media: {
      url,
      type: MEDIA_TYPES.IMAGE,
      ImgElement,
      backgroundColor: data.backgroundColor,
      imgProps: {
        src,
        alt,
        fill: true,
        ...blurOptions,
        ...extraImgProps,
      },
      ...options,
    },
    hideMedia: !src,
    hideImage: !src,
    extras: {
      caption,
    },
  }
}

export const dzMediaMapper = (media: DzMediaMapper) => {
  const { type } = media?.data ?? {}
  const mediaMapped =
    type === MediaTypes.VIDEO || type === MediaTypes.VIDEO_RECORD
      ? getVideoMedia(media as DzMediaVideoMapper)
      : getImageMedia(media as DzMediaImageMapper)

  const { asMediaCard } = media ?? {}

  /*
    Image -> Image, Image is used as the card view
    Image -> Custom Video, do no show video in card
    Image -> Video Record
    * If poster image is added, then the poster image should be used in the card view
    * If no poster image is added, then do not display video in card view.
  */

  const videoAsMediaCard = asMediaCard && mediaMapped?.media?.type === MEDIA_TYPES.VIDEO
  if (videoAsMediaCard && type === MediaTypes.VIDEO) {
    return {
      media: {},
      hideMedia: true,
      hideImage: true,
      extras: null,
    }
  }

  if (videoAsMediaCard) {
    const { videoProps, url } = mediaMapped?.media ?? {}
    const { source } = videoProps ?? {}
    const { posterImage } = source ?? {}
    const hideVideo = !posterImage
    return {
      media: {
        url,
        type: MEDIA_TYPES.IMAGE,
        ImgElement: Image,
        imgProps: {
          src: posterImage,
          alt: 'Media Image',
          fill: true,
          ...(media as DzMediaImageMapper)?.extraImgProps,
        },
        ...(media as DzMediaImageMapper)?.options,
      },
      hideMedia: hideVideo,
      hideImage: hideVideo,
      extras: null,
    }
  }

  return mediaMapped
}
