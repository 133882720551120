import Image from 'next/image'

import {
  EXHIBITION,
  EXHIBITIONS_URL,
  LEARN_MORE,
  ONLINE,
  ONLINE_EXHIBITION_TITLE,
} from '@/common/constants/commonCopies'
import { ctaMapperByMoleculeType } from '@/common/utilsMappers/cta.mapper'
import { mapStatus } from '@/common/utilsMappers/date.mapper'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { safeText } from '@/common/utilsMappers/safe'
import { MediaBuilderTypes } from '@/sanity/queries/components/builders/mediaBuilder'
import type { FieldsPerTypeSchemaType } from '@/sanity/queries/components/componentTypesData'

import { PageBuilderComponents } from '../types'

type exhibitionMapperArgs = {
  data: Extract<
    FieldsPerTypeSchemaType,
    { _type: 'exhibitionPage' | 'onlineExhibitionPage' | 'exceptionalWork' }
  >
}

const heroCTAMapper = ctaMapperByMoleculeType(PageBuilderComponents.dzHero)

export const exhibitionMapper = ({ data }: exhibitionMapperArgs) => {
  const {
    title,
    subtitle,
    artists,
    heroMedia,
    cardViewMedia,
    slug,
    ctaOptions,
    mediaOverride,
    enableOverrides,
  } = data

  const [primaryArtist] = artists ?? []
  const { fullName } = primaryArtist ?? {}

  const cardViewMediaSource =
    Object.keys(cardViewMedia ?? {}).length > 0 &&
    cardViewMedia?.type == MediaBuilderTypes.IMAGE &&
    cardViewMedia?.image
      ? cardViewMedia
      : null

  const { media } = dzMediaMapper({
    data: (enableOverrides ? mediaOverride : null) ?? cardViewMediaSource ?? heroMedia ?? data,
    ImgElement: Image,
    extraImgProps: {
      sizes: '100vw',
    },
  })
  const { status } = mapStatus(data)

  let locationName = ONLINE
  if (data._type === 'exhibitionPage') {
    const [primaryLocation] = data.locations ?? []
    const { name } = primaryLocation ?? {}
    locationName = name
  }

  let description: string | any[] = ''
  if (data._type === 'exceptionalWork') description = data.description ?? ''
  if (data._type !== 'exceptionalWork') description = data.summary ?? ''

  const descriptionText = safeText({ key: 'description', text: description })

  let eyebrow = data.eyebrow
  if (data._type === 'exhibitionPage') eyebrow = eyebrow ?? EXHIBITION
  if (data._type !== 'exhibitionPage') eyebrow = eyebrow ?? ONLINE_EXHIBITION_TITLE
  const overridePrimary = heroCTAMapper({ data: ctaOptions?.primaryCTA, options: { asLink: true } })
  const overrideSecondary = heroCTAMapper({
    data: ctaOptions?.secondaryCTA,
    options: { asLink: false },
  })

  return {
    media,
    category: eyebrow,
    title: title ?? fullName,
    subtitle,
    secondaryTitle: locationName,
    secondarySubtitle: status,
    ...descriptionText,
    linkCTA: {
      text: LEARN_MORE,
      url: slug.current ?? EXHIBITIONS_URL,
    },
    ...overridePrimary,
    ...overrideSecondary,
  }
}
