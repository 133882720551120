import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import tz from 'dayjs/plugin/timezone'

import { newYorkTimeZone } from '@/common/constants/timezone'

dayjs.extend(tz)
dayjs.extend(customParseFormat)

const NUM_TO_DAY = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

type Availability = {
  from: string
  to: string
}

type Hours = {
  _key: string
  availableTimes: Availability[]
}
export const parseAvailability = (hours: Hours[], currentTime: Date) =>
  hours
    .find((hour) => hour?._key?.toLowerCase() === NUM_TO_DAY[currentTime.getDay()])
    ?.availableTimes?.map((time) => {
      return {
        from: dayjs.tz(dayjs(time.from, 'h:mm A'), newYorkTimeZone).toDate(),
        to: dayjs.tz(dayjs(time.to, 'h:mm A'), newYorkTimeZone).toDate(),
      }
    })
