import { DzColumn } from '@zwirner/design-system'

import type { PageBuilderComponentsDataSchemaType } from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'
import cn from '@/utils/cn'

import { ErrorBoundary } from '../wrappers/ErrorBoundary'
import { DzMoleculeTitle } from './components/DzMoleculeTitle'
import { PageBuilderContainer } from './components/PageBuilderContainer'
import { DzCard } from './DzCard/DzCard'
import { CarouselMolecule } from './DzCarousel/DzCarousel'
import { showCarouselSection } from './DzCarousel/dzCarouselMapper'
import { DzEditorial } from './DzEditorial/DzEditorial'
import { DzGalleryHero } from './DzGalleryHero/DzGalleryHero'
import { DzGalleryLanding } from './DzGalleryLanding/DzGalleryLanding'
import { DzHero } from './DzHero/DzHero'
import { DzImageMosaic } from './DzImageMosaic/DzImageMosaic'
import { DzInterstitial } from './DzInterstitial/DzInterstitial'
import { showInterstitialSection } from './DzInterstitial/interstitialMapper'
import { DzMedia } from './DzMedia/DzMedia'
import { DzSplit } from './DzSplit/DzSplit'
import { showSplitSection } from './DzSplit/splitMappers'
import { GridMolecule } from './GridMolecule'
import { showGridSection } from './GridMolecule/gridMapper'
import type { PageBuilderNames } from './types'

// Get all supported page builder molecules, OneUp is only a wrapper

const componentsIndex: Record<PageBuilderNames, any> = {
  dzHero: DzHero,
  dzCard: DzCard,
  dzEditorial: DzEditorial,
  dzSplit: DzSplit,
  dzInterstitial: DzInterstitial,
  dzMedia: DzMedia,
  dzCarousel: CarouselMolecule,
  grid: GridMolecule,
  dzGalleryHero: DzGalleryHero,
  dzImageMosaic: DzImageMosaic,
  dzLanding: DzGalleryLanding,
}

const componentsValidators = {
  dzHero: () => true,
  dzCard: () => true,
  dzEditorial: () => true,
  dzSplit: showSplitSection,
  dzInterstitial: showInterstitialSection,
  dzMedia: () => true,
  dzCarousel: showCarouselSection,
  grid: showGridSection,
  dzGalleryHero: () => true,
  dzImageMosaic: () => true,
  dzLanding: () => true,
} as const

type PageBuilderProps = {
  id?: string
  components: PageBuilderComponentsDataSchemaType[]
  innerSection?: boolean
  containerClassName?: string
  className?: string
}

export const PageBuilder = ({
  id,
  components = [],
  innerSection = false,
  containerClassName = '',
  className = '',
}: PageBuilderProps) => {
  return (
    <ErrorBoundary>
      <DzColumn id={id} span={12} className={className}>
        <PageBuilderContainer innerSection={innerSection} contentLength={components.length}>
          {components?.map((component, idx) => {
            // content is the reference to the content type (artist, exhibition, article...)
            // props is the extra information required to render the component (CTA's, Overrides)
            const { _type, props, content = [] } = component
            const ComponentModule = componentsIndex[_type]
            const multipleContent = ComponentModule?.multipleContentTypes ?? false
            const notContentDependant = ComponentModule?.notContentDependant ?? false
            const componentContent = multipleContent ? content : content?.[0]

            if (!ComponentModule) {
              console.warn('PAGE BUILDER::: Not supported component:', _type)
              return null
            }
            if (!componentContent && !notContentDependant) {
              console.warn('PAGE BUILDER::: Please add content types to this component:', _type)
              return null
            }
            return (
              <div
                key={`${_type}-${idx}`}
                className={cn('flex flex-col gap-5 md:gap-10', containerClassName)}
              >
                {!['dzEditorial', 'dzInterstitial'].includes(_type) &&
                'dzMoleculeTitle' in component ? (
                  <span id={`section-title-${_type}-${idx}`}>
                    <DzMoleculeTitle data={component.dzMoleculeTitle} />
                  </span>
                ) : null}
                <span id={`molecule-${_type}-${idx}`}>
                  <ComponentModule data={componentContent} componentProps={props} />
                </span>
              </div>
            )
          })}
        </PageBuilderContainer>
      </DzColumn>
    </ErrorBoundary>
  )
}

export const showPageBuilderSection = (
  components: PageBuilderComponentsDataSchemaType[] | null
): components is PageBuilderComponentsDataSchemaType[] => {
  if (!components) return false
  return components?.some((component) => {
    const { _type } = component ?? {}
    const validator = componentsValidators[_type] ?? (() => true)
    return validator(component)
  })
}

export default PageBuilder
