import type { PropsWithChildren } from 'react'
import { Fragment } from 'react'

import { FullWidthFlexCol } from '@/components/containers/layout/FullWidthFlexCol'
import cn from '@/utils/cn'

type PageBuilderContainerProps = {
  contentLength: number
  innerSection: boolean
} & PropsWithChildren

export const PageBuilderContainer = (props: PageBuilderContainerProps) => {
  const { contentLength, children, innerSection } = props

  if (contentLength > 1) {
    return (
      <FullWidthFlexCol
        className={cn({
          'mb-0 flex w-full flex-col gap-[2.5rem] md:gap-[3.75rem]': innerSection,
        })}
      >
        {children}
      </FullWidthFlexCol>
    )
  }
  return <Fragment>{children}</Fragment>
}
