import { DzText, TEXT_TYPES } from '@zwirner/design-system'

import type { LocationSchemaDetailedType } from '@/sanity/queries/common/locationData'

type Props = {
  hours: LocationSchemaDetailedType['hours']
}
// TODO: Days and hours should be revisited and refactored
export const HoursBlock = ({ hours }: Props) => {
  if (!hours) return null
  const sunday = hours.at(-1)
  const restOfTheWeek = hours.slice(0, -1)
  const sundayFirstWeek = [sunday, ...restOfTheWeek] as LocationSchemaDetailedType['hours']
  return (
    <div>
      <DzText textSize="medium" className="mb-2.5 text-black-60" text="Hours" />
      <div>
        {sundayFirstWeek?.map(({ day, availableTimes }) => {
          const workingHours = availableTimes
            ?.map(({ from, to }) => {
              if (from && to) {
                const [fromHour, fromMinute, fromAMPM] = from.split(/[:\s]/)
                const renderedFrom =
                  fromHour + (fromMinute === '00' ? '' : `:${fromMinute}`) + ' ' + fromAMPM
                const [toHour, toMinute, toAMPM] = to.split(/[:\s]/)
                const renderedTo = toHour + (toMinute === '00' ? '' : `:${toMinute}`) + ' ' + toAMPM
                return `${renderedFrom}—${renderedTo}`
              }
              return ''
            })
            .filter(Boolean)
            .join(', ')
          return (
            <div key={day}>
              <DzText textSize="medium" textType={TEXT_TYPES.SPAN} text={day} />
              <DzText
                textType={TEXT_TYPES.SPAN}
                className="text-black-60"
                textSize="medium"
                text={` ${workingHours || 'CLOSED'}`}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
