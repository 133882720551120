import {
  GTMProductListingClickedText,
  GTMProductListingViewedText,
  GTMProductViewText,
} from '@/common/constants/gtmConstants'
import { mapEcommerceData } from '@/common/utilsMappers/gtm.mapper'
import type { ArtworkDataType } from '@/sanity/queries/artworks/artworkData'
import type { BookContentType } from '@/sanity/queries/components/content/bookContent'

import { gtmEvent } from './gtmEvent'

type EcommData = BookContentType | ArtworkDataType
export const gtmProductListingViewedEvent = (ecommObject: EcommData) => {
  gtmEvent(GTMProductListingViewedText.event, {
    ...GTMProductListingViewedText,
    ecommerce: mapEcommerceData(ecommObject, {
      item_list_id: true,
      item_list_name: true,
    }),
  })
}
export const gtmProductListingItemClickedEvent = (ecommObject: EcommData) => {
  const ecommerce = mapEcommerceData(ecommObject, {
    item_list_id: true,
    item_list_name: true,
    items: { item_list_id: true, item_list_name: true },
  })
  gtmEvent(GTMProductListingClickedText.event, {
    ...GTMProductListingClickedText,
    ecommerce,
  })
}
export const gtmProductViewEvent = (ecommObject: EcommData) => {
  const ecommerce = mapEcommerceData(ecommObject, {
    currency: true,
    items: { item_list_id: true, item_list_name: true, price: true },
  })
  gtmEvent(GTMProductViewText.event, {
    ...GTMProductViewText,
    ecommerce,
  })
}
